import Aos from "aos"
import { graphql, Link } from "gatsby"
import React from "react"
import HeroBanner from "../../components/HeroBanner"
import HistoryBlock from "../../components/HistoryBock"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { PortableText } from "@portabletext/react"
import CommonLink from "../../components/CommonLink"

const HistoryNoyo = ({ data }) => {
  React.useEffect(() => {
    Aos.init()
  }, [])
  const {
    title: pageTitle,
    historyPage: {
      title,
      _rawDescription,
      heroImg,
      totalYears,
      hotelInformation,
      bookLink,
    },
  } = data?.sanityPages
  return (
    <>
      <Seo
        title={pageTitle}
        description={"You know more about noyo-harbor, when it's establish."}
      />
      <Layout>
        <HeroBanner
          bannerImg={heroImg?.asset?.gatsbyImageData}
          pageClass={"history"}
        />
        <div className="history__desc">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3
                  className="text-center"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  {title}
                </h3>

                <div
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                >
                  <PortableText value={_rawDescription} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="history__area">
          <div className="container">
            <div className="history__heading">
              <h1
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1100"
              >
                {totalYears}
              </h1>
            </div>
          </div>
        </div>
        <div className="environmen__area history">
          <div className="container">
            {hotelInformation?.map((item, index) => (
              <HistoryBlock
                key={index}
                image={
                  item?.thumbnail === null
                    ? false
                    : item?.thumbnail?.asset.gatsbyImageData
                }
                imageCaption={item?.imgCaption}
                description={item.description}
                title={item.title}
                year={item.year}
                boxClass={"luxury__enviro__item"}
                index={index}
                length={hotelInformation.length}
              />
            ))}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div
                className="bed_btn text-center"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1300"
              >
                <CommonLink link={`${bookLink?.path}`} type={bookLink?.type}>
                  {bookLink?.label}
                </CommonLink>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default HistoryNoyo
export const historyInfo = graphql`
  query ($id: String!) {
    sanityPages(id: { eq: $id }) {
      title
      template
      handle {
        current
      }
      historyPage {
        title
        _rawDescription
        heroImg {
          asset {
            gatsbyImageData(fit: FILLMAX, formats: WEBP)
          }
        }
        totalYears
        hotelInformation {
          year
          title
          description
          thumbnail {
            asset {
              gatsbyImageData(fit: FILLMAX, formats: WEBP)
            }
          }
          imgCaption
        }
        bookLink {
          label
          path
          type
        }
      }
    }
  }
`
