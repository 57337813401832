import React, { useRef } from "react"
import useBoxSize from "../../../hooks/useBoxSize"
import useWindowSize from "../../../hooks/useWindowSize"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const HistoryBlock = ({
  image,
  description,
  year,
  title,
  index,
  imageCaption,
  data,
}) => {
  const { width } = useWindowSize()
  const boxRef = useRef()
  const swidth = useBoxSize({ boxRef: boxRef })
  return (
    <div className={`luxury_block`} style={{ marginTop: "65px" }}>
      <div className="row align-items-center">
        {
          <>
            <div
              className="history__year mb-4"
              data-aos="fade-up"
              data-aos-delay={`${50 + (index - 1) * 100}`}
              data-aos-duration={`${1100 + (index - 1) * 100}`}
            >
              <h1>{year}</h1>
              {year && <div className="underline__year" />}
            </div>
            {image ? (
              <>
                <div
                  className={index % 2 === 0 ? "col-lg-6 order-2" : "col-lg-6"}
                >
                  <figure
                    className="luxury_thumb"
                    data-aos="fade-up"
                    data-aos-delay={`${50 + (index - 1) * 100}`}
                    data-aos-duration={`${1100 + (index - 1) * 100}`}
                    ref={boxRef}
                  >
                    {/* <ImageWrapper
                      width={swidth}
                      bannerImg={image.src}
                      className={"history__thumb__bg"}
                    ></ImageWrapper> */}
                    <GatsbyImage
                      image={image}
                      alt="History Thumbnail"
                      objectFit="cover"
                      loading="eager"
                      style={{ maxHeight: "700px" }}
                    />
                    <figcaption className="history__thumb__bg__caption">
                      {imageCaption}
                    </figcaption>
                  </figure>
                </div>
                <div
                  className={index % 2 === 0 ? "col-lg-6 order-1" : "col-lg-6"}
                >
                  <div
                    className="luxury_content_blk"
                    data-aos="fade-up"
                    data-aos-delay={`${50 + (index - 1) * 100}`}
                    data-aos-duration={`${1100 + (index - 1) * 100}`}
                  >
                    <h4>{title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: description }} />
                  </div>
                </div>
              </>
            ) : (
              <div
                className="history__cntnt"
                data-aos="fade-up"
                data-aos-delay={`${50 + (index - 1) * 100}`}
                data-aos-duration={`${1100 + (index - 1) * 100}`}
              >
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            )}
          </>
        }
      </div>
    </div>
  )
}

export default HistoryBlock
const ImageWrapper = styled.div`
  background: ${props => `url(${props.bannerImg})`};
  background-size: contain;
  width: ${props => props.width}px;
  height: ${props => 0.75 * props.width}px;
  background-repeat: no-repeat;
  margin-top: 20px;
`
